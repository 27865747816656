import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import './index.css';

const hmacTokenKey = 'hmac_token';
const ENCODED_TOKEN_PARAM = 'token';
const ACCOUNT_ID_PARAM = 'account_id';
const websiteTokenKey = 'website_token'
const ENCODED_EXTERNAL_PARAMS = 'params';

const { REACT_APP_APP_STORE_URL, REACT_APP_HOORY_URL } = process.env;

const GET_INBOXES_URL = accountId => `${REACT_APP_HOORY_URL}/api/v1/accounts/${accountId}/inboxes`;
const POST_CONFIGS_URL = appId => `${REACT_APP_APP_STORE_URL}/applications/${appId}/configure`;
const GET_INVISIBLE_CONFIGS_URL = appId => `${REACT_APP_APP_STORE_URL}/applications/${appId}/config-fields?includeInvisibles=1`;

function Main() {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [ inboxes, setInboxes ] = useState([]);
  const [ selectedInbox, setSelectedInbox ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const configsStorage = JSON.parse(sessionStorage.getItem('configs'));
  const [ accountId ] = useState(searchParams.get(ACCOUNT_ID_PARAM));
  const encodedExternalParams = searchParams.get(ENCODED_EXTERNAL_PARAMS);
  const [ encodedToken ] = useState(searchParams.get(ENCODED_TOKEN_PARAM));
  const encodedExternalParamsStorage = sessionStorage.getItem('encodedExternalParams');

  useEffect(() => {
    !encodedExternalParamsStorage && sessionStorage.setItem('encodedExternalParams', encodedExternalParams);
    !encodedToken && (window.location.href = `${REACT_APP_HOORY_URL}/app/login?callback=${window.location.href}`);
  });

  useEffect(() => {
    if (encodedToken && accountId) {
      navigate('/', { replace: true });

      fetch(GET_INBOXES_URL(accountId), {
        headers: JSON.parse(atob(encodedToken)),
      })
        .then(resp => resp.json())
        .then(data => {
          setInboxes(data.payload);
          const { applicationId, componentId, ...headers } = JSON.parse(atob(encodedExternalParamsStorage));
          fetch(GET_INVISIBLE_CONFIGS_URL(applicationId), {
            headers: {
              "X-PROJECT-ID": headers?.projectId,
              "Authorization": `Bearer ${headers?.token}`,
            }
          })
            .then(resp => resp.json())
            .then(data => {
              sessionStorage.setItem('configs', JSON.stringify(data));
            });
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedToken, encodedExternalParamsStorage]);

  const sendInbox = useCallback(() => {
    if (selectedInbox) {
      setIsLoading(true);
      const config = configsStorage.data?.[0].configFields.filter(({ isVisible }) => !isVisible).map(({ alias, id }) => ({
        id,
        value: selectedInbox[alias]
      }));
      const { applicationId, componentId, ...headers } = JSON.parse(atob(encodedExternalParamsStorage));
      fetch(POST_CONFIGS_URL(applicationId), {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-PROJECT-ID": headers?.projectId,
          "Authorization": `Bearer ${headers?.token}`,
        },
        body: JSON.stringify({
          components: [
            {
              config,
              componentId
            }
          ]
        })
      })
        .then(() => {
          setIsSuccess(true);
          setTimeout(() => window.close(), 2500);
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line
  }, [selectedInbox, configsStorage, encodedExternalParamsStorage]);

  return (
    <div className="main-holder">
      {!isLoading && inboxes?.length ? (
        <>
          <div className="title-wrapper">
            <img src={`${REACT_APP_HOORY_URL}/brand-assets/logo_thumbnail.svg`} alt="Hoory" />
            <h1>Pick an Inbox</h1>
          </div>
          <ul className="inbox-holder">
            {inboxes?.map(inbox => {
              const {
                id,
                name,
                [hmacTokenKey]: hmacToken,
                [websiteTokenKey]: websiteToken
              } = inbox;
              const isSelected = selectedInbox?.id === inbox?.id;
              return (
                <li
                  key={id}
                  onClick={() => hmacToken && websiteToken && setSelectedInbox(isSelected ? null : inbox)}
                  className={`inbox ${isSelected ? 'selected' : ''} ${!hmacToken || !websiteToken ? 'disabled' : ''}`}
                >
                  {name}
                </li>
              )
            })}
          </ul>
          <div className="confirm-button-holder">
            <button onClick={() => sendInbox()} disabled={!selectedInbox} className="confirm-button">Confirm</button>
          </div>
        </>
      ) : (
        isSuccess ? (
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
        ) : (
          <span className="loader"></span>
        )
      )}
    </div>
  );
}

export default Main;
